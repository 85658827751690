import { withStyles } from "@material-ui/core/styles"
import MenuItem from "@material-ui/core/MenuItem"
import { FONT_FAMILY } from "../constants"

const FormMenuItem = withStyles({
  root: {
    fontFamily: FONT_FAMILY,
  },
})(MenuItem)

export default FormMenuItem
