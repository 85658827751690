import React from "react"
import { graphql, Link } from "gatsby"
import GatsbyImage from "gatsby-image"
import { Product, CartItem } from "../types"
import Layout from "../components/Layout"
import ProductForm from "../components/ProductForm"
import ProductLinkContainer from "../components/ProductLinkContainer"
import { HOME_ROUTE, SEASONAL_ROUTE, WEDDING_ROUTE } from "../constants"
import ProductImageCarousel from "../components/ProductImageCarousel"

interface ProductPageComponentProps {
  products: Product[]
  productId: string
  category: string
  addToCart?: (cartItem: CartItem) => void
  seasonalPageColor: string
  seasonalPageTitle: string
  additionalProductImages: Product["productImage"][]
}

const ProductPageComponent: React.FC<ProductPageComponentProps> = ({
  addToCart,
  products,
  productId,
  category,
  seasonalPageTitle,
  additionalProductImages,
}) => {
  let buttonClassName: string
  let viewAllRoute: string
  let buttonText: string
  if (category === "Home") {
    buttonClassName = "light-orange-btn"
    viewAllRoute = HOME_ROUTE
    buttonText = "VIEW ALL HOME DECOR"
  } else if (category === "Wedding") {
    buttonClassName = "pink-btn"
    viewAllRoute = WEDDING_ROUTE
    buttonText = "VIEW ALL WEDDING DECOR"
  } else if (category === "Seasonal") {
    buttonClassName = "pink-btn"
    viewAllRoute = SEASONAL_ROUTE
    buttonText = `VIEW ALL ${seasonalPageTitle.toUpperCase()}`
  }
  let activeProduct: Product
  const additionalProducts: Product[] = []
  for (const product of products) {
    if (product.id === productId) {
      activeProduct = product
    } else {
      additionalProducts.push(product);
    }
  }
  if (!activeProduct) {
    return null
  }
  return (
    <div className="layout-container">
      <div className="product-page-body">
        <div className="grid-container add-width-2">
          <div className="grid-item-2 padding-2">
            {additionalProductImages.length === 0 ? (
              <div className="image-container">
                <GatsbyImage
                  fluid={activeProduct.productImage.childImageSharp.fluid}
                />
              </div>
            ) : (
              <ProductImageCarousel
                product={activeProduct}
                additionalProductImages={additionalProductImages}
              />
            )}
          </div>
          <div className="grid-item-2 padding-2">
            <h4>{activeProduct.name}</h4>
            <div
              dangerouslySetInnerHTML={{ __html: activeProduct.description }}
            />
            <div className="margin-bottom-4">
              <p>
                <strong>Lead Time:</strong> {activeProduct.leadTime}{" "}
                <span className="text-muted">
                  <small>(not including shipping time)</small>
                </span>
              </p>
            </div>
            <ProductForm product={activeProduct} addToCart={addToCart} />
          </div>
        </div>
        <div className="additional-products-container">
          <h6>More {category} Creations for Your Inspiration</h6>
          <div className="grid-container product-grid">
            {additionalProducts.sort((a) => a.subcategory === activeProduct.subcategory ? -1 : 1)
              .slice(0, 12)
              .map((product, index) => {
                return (
                  <ProductLinkContainer
                    product={product}
                    additionalProductItem
                    key={`product-${index}`}
                  />
                )
              })}
          </div>
          <div className="text-center padding-top-bottom-2">
            <Link
              className={`full-width-mobile btn rounded-btn ${buttonClassName}`}
              to={viewAllRoute}
            >
              {buttonText}
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

const ProductPage = ({
  data: {
    allProduct: { nodes },
    allAdditionalProductImage,
    pageContent: { seasonalPageColor, seasonalPageTitle },
  },
  pageContext: {
    name,
    id,
    resizedImageURL,
    sanitizedDescription,
    category,
    pathname,
  },
}) => {
  const additionalProductImages = allAdditionalProductImage.nodes.map(
    ({ image }) => ({ ...image })
  )
  return (
    <Layout
      pageTitle={name}
      cartProps
      ogImageURL={resizedImageURL}
      ogDescription={sanitizedDescription}
      pathname={pathname}
    >
      <ProductPageComponent
        products={nodes as Product[]}
        productId={id}
        category={category}
        seasonalPageColor={seasonalPageColor}
        seasonalPageTitle={seasonalPageTitle}
        additionalProductImages={additionalProductImages}
      />
    </Layout>
  )
}

export default ProductPage

export const query = graphql`
  query($category: String!, $id: String!) {
    allProduct(filter: { category: { eq: $category } }) {
      nodes {
        productImage {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        category
        defaultStainColor
        defaultVinylColor
        description
        featured
        id
        imageUploadEnabled
        imageUploadHelperText
        leadTime
        name
        minPrice
        multiplePriceOptions
        path
        personalizationRequired
        personalizationInputType
        personalizationDescription
        priceOptionArray {
          description
          price
        }
        quantityInputEnabled
        stainColorPickerEnabled
        subcategory
        styleOptionsEnabled
        styleOptionColorsEnabled
        styleOptionLabel
        styleOptionHelperText
        styleOptions {
          description
          primaryColor
          secondaryColor
        }
        vinylColorPickerEnabled
      }
    }
    pageContent {
      seasonalPageColor
      seasonalPageTitle
    }
    allAdditionalProductImage(filter: { productId: { eq: $id } }) {
      nodes {
        image {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
