import React from "react"
import GatsbyImage from "gatsby-image"
import { Product } from "../types"
import Icon from "./Icon"

const ASPECT_RATIO = 1
const ProductImageCarousel: React.FC<{
  product: Product
  additionalProductImages: Product["productImage"][]
}> = ({ product, additionalProductImages }) => {
  const imageRef = React.useRef<HTMLDivElement>(null)
  const [carouselIndex, setCarouselIndex] = React.useState(0)
  const [imageIndex, setImageIndex] = React.useState(0)
  const [imageOpacity, setImageOpacity] = React.useState(1)
  const [init, setInit] = React.useState(true)
  React.useEffect(() => {
    if (!init) {
      setImageOpacity(0)
    } else {
      setInit(false)
    }
  }, [carouselIndex])
  React.useEffect(() => {
    const listener = (event: any) => {
      if (event.target.style.opacity === "0") {
        setImageIndex(carouselIndex)
        setImageOpacity(1)
      }
    }
    imageRef.current.addEventListener("transitionend", listener)
    return () => imageRef.current.removeEventListener("transitionend", listener)
  }, [carouselIndex])
  const productImages = [product.productImage, ...additionalProductImages]
  const handleIncrement = () => {
    if (carouselIndex === productImages.length - 1) {
      setCarouselIndex(0)
    } else {
      setCarouselIndex(currentIndex => currentIndex + 1)
    }
  }
  const handleDecrement = () => {
    if (carouselIndex === 0) {
      setCarouselIndex(productImages.length - 1)
    } else {
      setCarouselIndex(currentIndex => currentIndex - 1)
    }
  }
  return (
    <>
      <div className="carousel-grid">
        <div className="carousel-btn-container">
          <button
            className="carousel-btn flex-justify-center prev"
            onClick={handleDecrement}
          >
            <Icon iconName="chevronLeft" width={20} />
          </button>
        </div>
        <div className="carousel-image-container">
          <div className="carousel-image">
            <div
              className="carousel-opacity-wrapper"
              style={{ opacity: imageOpacity }}
              ref={imageRef}
            >
              <GatsbyImage
                fluid={{
                  ...productImages[imageIndex].childImageSharp.fluid,
                  aspectRatio: ASPECT_RATIO,
                }}
                loading="eager"
              />
            </div>
          </div>
        </div>
        <div className="carousel-btn-container">
          <button
            className="carousel-btn flex-justify-center next"
            onClick={handleIncrement}
          >
            <Icon iconName="chevronRight" width={20} />
          </button>
        </div>
      </div>
      <div className="carousel-thumbnail-grid-wrapper">
        <div className="carousel-thumbnail-grid">
          {productImages.map((image, index) => {
            const handleClick = () => {
              setCarouselIndex(index)
            }
            return (
              <div
                className="carousel-thumbnail-container"
                key={`thumb-${index}`}
              >
                <button
                  className={`carousel-thumbnail${
                    carouselIndex === index ? " active" : ""
                  }`}
                  onClick={handleClick}
                >
                  <GatsbyImage
                    fluid={{
                      ...image.childImageSharp.fluid,
                      aspectRatio: ASPECT_RATIO,
                    }}
                  />
                </button>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default ProductImageCarousel
