import React from "react"
import { Field } from "formik"
import Button from "@material-ui/core/Button"
import Icon from "./Icon"

const IMAGE_BLOB = "imageBlob"

const ImageField: React.FC<{ helperText: string }> = ({ helperText }) => {
  if (typeof window !== "undefined") {
    return (
      <Field name="image">
        {({ field, form, meta }) => {
          function removeImage() {
            form.setFieldValue(IMAGE_BLOB, "")
            form.setFieldValue(field.name, null)
          }
          return (
            <div className="margin-bottom-2">
              <h6 className="margin-bottom-1">{helperText}</h6>
              {form.values[IMAGE_BLOB] ? (
                <div className="flex-center">
                  <div>
                    <img
                      src={form.values[IMAGE_BLOB]}
                      alt="Upload result"
                      className="form-placeholder-image"
                    />
                  </div>
                  <div>
                    <button
                      type="button"
                      className="btn remove-img-btn"
                      onClick={removeImage}
                    >
                      REMOVE
                    </button>
                  </div>
                </div>
              ) : (
                <>
                  <input
                    accept="image/*"
                    className="display-none"
                    id="image-file-button"
                    multiple
                    type="file"
                    onChange={event => {
                      form.setFieldTouched(field.name, true)
                      const file = event.currentTarget.files[0]
                      const fileSize = file.size / 1024 / 1024
                      if (fileSize > 3) {
                        window.alert(
                          "This file is too large. Please use a smaller file"
                        )
                      } else {
                        form.setFieldValue(field.name, file)
                        const reader = new FileReader()
                        reader.onloadend = () => {
                          form.setFieldValue(IMAGE_BLOB, reader.result)
                        }
                        reader.readAsDataURL(file)
                      }
                    }}
                  />
                  <label htmlFor="image-file-button">
                    <Button
                      variant="outlined"
                      component="span"
                      startIcon={<Icon iconName="camera" width={22} />}
                      className="full-width-mobile"
                    >
                      Upload Image
                    </Button>
                  </label>
                  {meta.touched && meta.error && (
                    <div>
                      <span className="error-msg">{meta.error}</span>
                    </div>
                  )}
                </>
              )}
            </div>
          )
        }}
      </Field>
    )
  }
  return null
}

export default ImageField
