import React from "react"
import MenuItem from "@material-ui/core/MenuItem"
import Dialog from "@material-ui/core/Dialog"
import InputLabel from "@material-ui/core/InputLabel"
import FormControl from "@material-ui/core/FormControl"
import FormHelperText from "@material-ui/core/FormHelperText"
import Select from "@material-ui/core/Select"
import { Field, FieldProps } from "formik"
import { STAIN_COLORS, VINYL_COLORS } from "../constants"
import { ColorPickerValue, StainColor, VinylColor } from "../types"
import useStainImages from "./useStainImages"

interface ColorSelectProps {
  name: "stainColor" | "vinylColor"
}
const ColorSelect: React.FC<ColorSelectProps> = ({ name }) => {
  const stainImages = useStainImages()
  let colors: ColorPickerValue[]
  let label: string
  let helperText: string
  let menuItemRenderer: (color: ColorPickerValue) => JSX.Element
  let dialogItemRenderer: (color: ColorPickerValue) => JSX.Element
  if (name === "stainColor") {
    label = "Stain Color"
    helperText = "Select the color of the stain used for the wooden frame"
    colors = STAIN_COLORS
    menuItemRenderer = (color: ColorPickerValue) => (
      <div className="style-option-color stain">
        <img
          src={stainImages[(color as StainColor).imageKey].publicURL}
          alt={color.value}
        />
      </div>
    )
    dialogItemRenderer = (color: ColorPickerValue) => (
      <div className="color-display">
        <img
          src={stainImages[(color as StainColor).imageKey].publicURL}
          alt={color.value}
        />
      </div>
    )
  } else {
    label = "Vinyl Color"
    helperText =
      "Select the color of the vinyl used for the text and foreground"
    colors = VINYL_COLORS
    menuItemRenderer = (color: ColorPickerValue) => (
      <div
        className="style-option-color"
        style={{ background: (color as VinylColor).hex }}
      />
    )
    dialogItemRenderer = (color: ColorPickerValue) => (
      <div
        className="color-display"
        style={{ backgroundColor: (color as VinylColor).hex }}
      />
    )
  }
  const [open, setOpen] = React.useState(false)
  const handleClose = () => {
    setOpen(false)
  }
  const handleOpen = () => {
    setOpen(true)
  }
  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => {
        return (
          <div className="margin-bottom-2">
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor={`${name}-input`}>{label}</InputLabel>
              <Select
                value={field.value}
                label={label}
                inputProps={{
                  name,
                  id: `${name}-input`,
                }}
                onOpen={handleOpen}
                open={false}
              >
                {colors.map((color, index) => {
                  return (
                    <MenuItem value={color.value} key={`option-${index}`}>
                      <div className="flex-center">
                        <div>{menuItemRenderer(color)}</div>
                        <div>
                          <span>{color.value}</span>
                        </div>
                      </div>
                    </MenuItem>
                  )
                })}
              </Select>
              <FormHelperText>{helperText}</FormHelperText>
            </FormControl>
            <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
              <div className="padding-1">
                <h5 className="margin-bottom-1 text-center">Select {label}</h5>
                <div className="grid-container flex-justify-center">
                  {colors.map((color, index) => {
                    function handleClick() {
                      form.setFieldValue(name, color.value)
                      handleClose()
                    }
                    return (
                      <div
                        className="padding-1 color-grid-item"
                        key={`${name}-${index}`}
                      >
                        <button
                          onClick={handleClick}
                          className={`btn color-btn${
                            color.value === field.value ? " selected" : ""
                          }`}
                        >
                          <div>{dialogItemRenderer(color)}</div>
                          <div>
                            <p className="color-label text-truncate">
                              {color.value}
                            </p>
                          </div>
                        </button>
                      </div>
                    )
                  })}
                </div>
              </div>
            </Dialog>
          </div>
        )
      }}
    </Field>
  )
}

export default ColorSelect
