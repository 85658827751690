import { useStaticQuery, graphql } from "gatsby"

export default function useStainImages() {
  const data = useStaticQuery(graphql`
    query {
      agedBarrel: file(relativePath: { eq: "agedBarrel.jpg" }) {
        publicURL
      }
      barnRed: file(relativePath: { eq: "barnRed.jpg" }) {
        publicURL
      }
      cherry: file(relativePath: { eq: "cherry.jpg" }) {
        publicURL
      }
      classicGrey: file(relativePath: { eq: "classicGrey.jpg" }) {
        publicURL
      }
      colonialMaple: file(relativePath: { eq: "colonialMaple.jpg" }) {
        publicURL
      }
      darkWalnut: file(relativePath: { eq: "darkWalnut.jpg" }) {
        publicURL
      }
      driftwood: file(relativePath: { eq: "driftwood.jpg" }) {
        publicURL
      }
      earlyAmerican: file(relativePath: { eq: "earlyAmerican.jpg" }) {
        publicURL
      }
      ebony: file(relativePath: { eq: "ebony.jpg" }) {
        publicURL
      }
      englishChestnut: file(relativePath: { eq: "englishChestnut.jpg" }) {
        publicURL
      }
      espresso: file(relativePath: { eq: "espresso.jpg" }) {
        publicURL
      }
      fruitwood: file(relativePath: { eq: "fruitwood.jpg" }) {
        publicURL
      }
      goldenOak: file(relativePath: { eq: "goldenOak.jpg" }) {
        publicURL
      }
      goldenPecan: file(relativePath: { eq: "goldenPecan.jpg" }) {
        publicURL
      }
      gunstock: file(relativePath: { eq: "gunstock.jpg" }) {
        publicURL
      }
      honey: file(relativePath: { eq: "honey.jpg" }) {
        publicURL
      }
      ipswichPine: file(relativePath: { eq: "ipswichPine.jpg" }) {
        publicURL
      }
      jacobean: file(relativePath: { eq: "jacobean.jpg" }) {
        publicURL
      }
      mocha: file(relativePath: { eq: "mocha.jpg" }) {
        publicURL
      }
      natural: file(relativePath: { eq: "natural.jpg" }) {
        publicURL
      }
      navy: file(relativePath: { eq: "navy.jpg" }) {
        publicURL
      }
      pickledOak: file(relativePath: { eq: "pickledOak.jpg" }) {
        publicURL
      }
      provincial: file(relativePath: { eq: "provincial.jpg" }) {
        publicURL
      }
      puritanPine: file(relativePath: { eq: "puritanPine.jpg" }) {
        publicURL
      }
      redChestnut: file(relativePath: { eq: "redChestnut.jpg" }) {
        publicURL
      }
      redMahogany: file(relativePath: { eq: "redMahogany.jpg" }) {
        publicURL
      }
      redOak: file(relativePath: { eq: "redOak.jpg" }) {
        publicURL
      }
      rusticBeige: file(relativePath: { eq: "rusticBeige.jpg" }) {
        publicURL
      }
      sedonaRed: file(relativePath: { eq: "sedonaRed.jpg" }) {
        publicURL
      }
      silveredGrey: file(relativePath: { eq: "silveredGrey.jpg" }) {
        publicURL
      }
      simplyWhite: file(relativePath: { eq: "simplyWhite.jpg" }) {
        publicURL
      }
      slate: file(relativePath: { eq: "slate.jpg" }) {
        publicURL
      }
      specialWalnut: file(relativePath: { eq: "specialWalnut.jpg" }) {
        publicURL
      }
      trueBlack: file(relativePath: { eq: "trueBlack.jpg" }) {
        publicURL
      }
      vintageBlue: file(relativePath: { eq: "vintageBlue.jpg" }) {
        publicURL
      }
      weatheredOak: file(relativePath: { eq: "weatheredOak.jpg" }) {
        publicURL
      }
    }
  `)
  return data
}
