import React from "react"
import { Formik, Form, Field, FieldProps } from "formik"
import Loadable from "react-loadable"
import TextField from "@material-ui/core/TextField"
import MenuItem from "./FormMenuItem"
import { Product, CartItem } from "../types"
import { convertCentsToCurrency } from "../utils"
import ColorSelect from "./ColorSelect"
import ImageField from "./ImageField"
import useFirebase from "../useFirebase"

const LoadableTextEditorComponent = Loadable({
  loader: () => import("./RichTextEditor"),
  loading: () => (
    <div className="text-editor-loading-container">
      <div className="loading-header" />
      <div className="loading-body">Loading...</div>
    </div>
  ),
  delay: 100,
})

const ProductForm: React.FC<{
  product: Product
  addToCart: (cartItem: CartItem) => void
}> = ({ product, addToCart }) => {
  const firebase = useFirebase()
  const processImage = async (file: File): Promise<string> => {
    const prefix = "customers/"
    if (file) {
      const storageRef = firebase.storage().ref()
      const fileName = `customer-upload-${Date.now()}`
      await storageRef.child(`${prefix}${fileName}`).put(file)
      return prefix + fileName
    }
    return ""
  }
  const buttonClassName =
    product.category === "Home" ? "light-orange-btn" : "pink-btn"
  const initialValues: { [key: string]: string } = {
    personalization: "",
    priceOption: product.priceOptionArray[0].description,
    styleOption: product.styleOptionsEnabled
      ? product.styleOptions[0].description
      : "",
  }
  if (product.stainColorPickerEnabled) {
    initialValues.stainColor = product.defaultStainColor
  }
  if (product.vinylColorPickerEnabled) {
    initialValues.vinylColor = product.defaultVinylColor
  }
  if (product.imageUploadEnabled) {
    initialValues.image = null
    initialValues.imageBlob = ""
  }
  if (product.quantityInputEnabled) {
    initialValues.quantity = "1"
  }
  const priceOptions = product.priceOptionArray.reduce(
    (a: { [key: string]: { price: number } }, { description, price }) => {
      a[description] = { price }
      return a
    },
    {}
  )
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, actions) => {
        actions.setStatus("")
        try {
          const cartItem: CartItem = {
            multiplePriceOptions: product.multiplePriceOptions,
            name: product.name,
            productId: product.id,
            productImage: product.productImage,
            price: priceOptions[values.priceOption].price,
            priceOption: values.priceOption,
            personalization: values.personalization,
            quantity: product.quantityInputEnabled
              ? parseInt(values.quantity)
              : 1,
            stainColor: values.stainColor,
            styleOption: values.styleOption,
            styleOptionLabel: product.styleOptionLabel,
            vinylColor: values.vinylColor,
          }
          if (
            product.personalizationRequired &&
            product.personalizationInputType === "Rich Text"
          ) {
            cartItem.richTextPersonalization = true
          }
          if (product.imageUploadEnabled) {
            const imagePath = await processImage(values.image as any)
            cartItem.imagePath = imagePath
          }
          addToCart(cartItem)
          actions.resetForm()
        } catch {
          actions.setStatus(
            "An error occurred adding your item. Please try again and contact us if the issue persists."
          )
        }
      }}
      validate={values => {
        const errors: { [key: string]: string } = {}
        if (product.personalizationRequired && !values.personalization) {
          errors.personalization = "Please include a personalization message"
        }
        if (product.imageUploadEnabled && !values.image) {
          errors.image = "Please upload an image"
        }
        return errors
      }}
    >
      {({ values, isSubmitting, status }) => {
        return (
          <Form>
            {product.multiplePriceOptions && (
              <Field name="priceOption">
                {({ field }) => {
                  return (
                    <div className="margin-bottom-2">
                      <TextField
                        {...field}
                        label="Product Option"
                        variant="outlined"
                        fullWidth
                        select
                      >
                        {product.priceOptionArray.map(
                          ({ description, price }) => {
                            return (
                              <MenuItem value={description} key={description}>
                                {description}
                                <span className="price-display">
                                  (${convertCentsToCurrency(price)})
                                </span>
                              </MenuItem>
                            )
                          }
                        )}
                      </TextField>
                    </div>
                  )
                }}
              </Field>
            )}
            {product.styleOptionsEnabled && (
              <Field name="styleOption">
                {({ field }) => {
                  return (
                    <div className="margin-bottom-2">
                      <TextField
                        {...field}
                        label={product.styleOptionLabel}
                        variant="outlined"
                        fullWidth
                        helperText={product.styleOptionHelperText || ""}
                        select
                      >
                        {product.styleOptions.map(
                          (
                            { description, primaryColor, secondaryColor },
                            index
                          ) => {
                            return (
                              <MenuItem
                                value={description}
                                key={`option-${index}`}
                              >
                                <div className="flex-center">
                                  {product.styleOptionColorsEnabled && (
                                    <div>
                                      <div
                                        className="style-option-color"
                                        style={{
                                          background: `linear-gradient(45deg, ${primaryColor}, ${
                                            secondaryColor
                                              ? secondaryColor
                                              : primaryColor
                                          }`,
                                        }}
                                      />
                                    </div>
                                  )}
                                  <div>
                                    <span>{description}</span>
                                  </div>
                                </div>
                              </MenuItem>
                            )
                          }
                        )}
                      </TextField>
                    </div>
                  )
                }}
              </Field>
            )}
            {product.stainColorPickerEnabled && (
              <ColorSelect name="stainColor" />
            )}
            {product.vinylColorPickerEnabled && (
              <ColorSelect name="vinylColor" />
            )}
            {product.personalizationRequired && (
              <div>
                <h6 className="margin-bottom-1">Add your personalization</h6>
                <div
                  dangerouslySetInnerHTML={{
                    __html: product.personalizationDescription,
                  }}
                  className="personalization-section"
                />
                <div className="margin-bottom-2">
                  <Field name="personalization">
                    {(props: FieldProps) => {
                      if (product.personalizationInputType === "Rich Text") {
                        return <LoadableTextEditorComponent {...props} />
                      }
                      const { field, meta } = props
                      const hasError = Boolean(meta.touched && meta.error)
                      return (
                        <TextField
                          {...field}
                          label="Personalization"
                          multiline
                          variant="outlined"
                          fullWidth
                          helperText={hasError && meta.error}
                          error={hasError}
                        />
                      )
                    }}
                  </Field>
                </div>
              </div>
            )}
            {product.imageUploadEnabled && (
              <ImageField helperText={product.imageUploadHelperText} />
            )}
            {product.quantityInputEnabled && (
              <Field name="quantity">
                {({ field }) => {
                  return (
                    <div className="margin-bottom-2">
                      <TextField
                        {...field}
                        label="Quantity"
                        select
                        fullWidth
                        variant="outlined"
                        SelectProps={{ native: true }}
                      >
                        {Array.from({ length: 99 }).map((val, index) => {
                          return (
                            <option key={`qty-${index}`} value={`${index + 1}`}>
                              {index + 1}
                            </option>
                          )
                        })}
                      </TextField>
                    </div>
                  )
                }}
              </Field>
            )}
            <h5 className="margin-bottom-2">
              {product.quantityInputEnabled && parseInt(values.quantity) > 1 ? (
                <>
                  <span>
                    $
                    {convertCentsToCurrency(
                      priceOptions[values.priceOption].price *
                        parseInt(values.quantity)
                    )}{" "}
                  </span>
                  <span className="text-muted">
                    <small>
                      ($
                      {convertCentsToCurrency(
                        priceOptions[values.priceOption].price
                      )}{" "}
                      each)
                    </small>
                  </span>
                </>
              ) : (
                <span>
                  $
                  {convertCentsToCurrency(
                    priceOptions[values.priceOption].price
                  )}
                </span>
              )}
            </h5>
            {status && (
              <div className="margin-bottom-1">
                <span className="error-msg">{status}</span>
              </div>
            )}
            <button
              className={`btn rounded-btn ${buttonClassName} full-width${
                isSubmitting ? " disabled" : ""
              }`}
              type="submit"
            >
              <strong>ADD TO CART</strong>
            </button>
          </Form>
        )
      }}
    </Formik>
  )
}

export default ProductForm
